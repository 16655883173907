import { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import trainingFooterDesktop from "../../../images/trainingFooterDesktop.png"

const HomePageForm = (props) => {
  const navigate = useNavigate();

  const [phoneValidation, setPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation().pathname;
  const date = new Date();
  const [address, setAddress]= useState("");


  // declaration of refs
  const phoneRef = useRef(0);
  const dateRef = useRef("");
  const addressRef = useRef("");
  const petRef = useRef("");

  async function submitWalkingTrainingDataHandler(path) {
    let link = "";
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;

    const stringPhone = phoneRef.current.value.toString();

    let UTM_Source, UTM_Medium,UTM_Campaign= "";
        
    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get('utm_source');
    UTM_Medium = urlParams.get('utm_medium');
    UTM_Campaign = urlParams.get('utm_campaign')
    console.log(dateRef.current.value)
    let data = {data:{
      ContactNo: phoneRef.current.value,
      DateOfRegistration: dateRef.current.value || dateOfReg,
      TimeOfRegistration: timeOfReg,
      Address: addressRef.current.value,
      location: address,
      AnimalType:"Cat",
      FormType: "Direct Form",
      UTM_Source:UTM_Source || "",
      UTM_Medium:UTM_Medium || "",
      UTM_Campaign:UTM_Campaign || "",
      TrialDate: "",
    }};

    if (addressRef !== "" && stringPhone.length >= 10) {
      console.log("hey",path)
      setLoading(true);
      if (path === "walking") {
        link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
      } else if (path === "training") {
        console.log("Submitting the training data!");
        link = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData";
      }

      await axios
        .post(link, data)
        .then((response) => {
          console.log("hey2",response);
        })
        .catch((err) => {
          console.log("hey3",err);
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${path} Registration Fired From Desktop Service Page Form`,
      });

      phoneRef.current.value = "";
      setLoading(false);
      navigate("/thankPage");
    } else {
      setPhoneValidation(false);
      phoneRef.current.value = "";
    }
  }

  return (
    <div className="home-page-desktop-intro-form-container2">
      <div className="walking-page-desktop-intro-form-header">
        <div>Book a FREE CAT Training Session</div>
      </div>
      <div className="walking-page-desktop-intro-form-contents-container">
        <div className="walking-page-desktop-intro-form-contents-upper">
          {location == "/cat-training" && (
            <>
              <div className="walking-page-desktop-intro-form-contents-upper-left">
                <div className="home-page-desktop-input-container">
                  <div>Phone Number </div>

                  {phoneValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Please enter a valid phone number
                    </div>
                  )}
                </div>
                <input
                  onChange={() => setPhoneValidation(true)}
                  ref={phoneRef}
                  className="walking-page-phone-input"
                  type="number"
                  placeholder="Enter phone number"
                  name="phone"
                  required
                />
              </div>
              <div className='walking-page-desktop-intro-form-contents-upper-right'>
                        <div className='home-page-intro-bottom-btn'>
                            {
                                loading ? (<LoadingSpinner />) : <button onClick = {() => submitWalkingTrainingDataHandler("training")} className="booksession-form">Book a FREE Session</button>
                            }
                            
                        </div>
               </div>
            </>
          )}

          {location == "/dog-training-meta" && (
            
            <div style={{width:"100%"}}>
            <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
            <div className="walking-page-desktop-intro-form-contents-upper-left">
                <div className="home-page-desktop-input-container">
                  <div>Phone Number </div>

                  {phoneValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Please enter a valid phone number
                    </div>
                  )}
                </div>
                <input
                  onChange={() => setPhoneValidation(true)}
                  ref={phoneRef}
                  className="walking-page-phone-input"
                  type="number"
                  placeholder="Enter phone number"
                  name="phone"
                  required
                />
                
              </div>
              
              <div className="walking-page-desktop-intro-form-contents-upper-left" style={{display:"flex",justifyContent:"end"}}>
              <div>
              <div className="home-page-desktop-input-container">
                <div>Pet Name</div>

                {petValidation ? null : (
                  <div className="home-page-desktop-phone-validation-text">
                    Please enter a pet name
                  </div>
                )}
              </div>
              <input
                onChange={() => setPetValidation(true)}
                ref={petRef}
                className="walking-page-phone-input"
                type="text"
                placeholder="Enter pet name"
                name="petname"
                required
              />
              </div>
            </div>
            </div>
            <div style={{marginTop:"20px",width:"100%",display:"flex",justifyContent:"center"}}>
            <div className='walking-page-desktop-intro-form-contents-upper-right'>
                        <div className='home-page-intro-bottom-btn'>
                            {
                                loading ? (<LoadingSpinner />) : <button onClick = {() => submitWalkingTrainingDataHandler("training")} className="booksession-form">Book a FREE Session</button>
                            }
                            
                        </div>
               </div>
            </div>
            </div>
          )}
        </div>
        <div style={{ paddingTop: "2rem" }}>
            <img
              src={trainingFooterDesktop}
              style={{ width: "40rem", height: "10rem" }}
            />
          </div>
          
        <div className="walking-page-desktop-intro-form-contents-lower">
          <i>We are mission driven & a Family of Pet Lovers</i>
        </div>
      </div>
    </div>
  );
};

export default HomePageForm;
