import "./MobileFormsCSS.css";
import { useState, useEffect, useRef } from "react";
import LazyLoad from "react-lazyload";
import axios from "axios";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import otptick from "../../../images/otptick.png";
import trainingFooterMobile from "../../../images/trainingFooterMobile.png";
import phoneIcon from "../../../images/telephoneIcon.png";
import dogsIcon from "../../../images/dogsIcon.png";
import locationIcon from "../../../images/locationIcon.png";

const TrainingPageForm = (props) => {
    const navigate = useNavigate();
    const sectionRef = useRef(null);

    const trainingPhoneRef = useRef(0);

    // const [petValidation, setPetValidation] = useState(true);
    const [petNameValidation, setNamePetValidation] = useState(true);
    const [cityValidation, setCityValidation] = useState("")
    const [trainingPhoneValidation, setTrainingPhoneValidation] = useState(true);
    const [trainingLoading, setTrainingLoading] = useState(false);
    const [address, setAddress] = useState("");
    const [petName, setPetName] = useState("");
    const [city, setCity] = useState("");

    async function trainingSubmissionHandler() {
        const inputPhone = trainingPhoneRef.current.value;

        const elapsed = Date.now();
        const date = new Date(elapsed);
        const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
            }-${date.getFullYear()}`;
        const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

        let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

        //   handleLocationAccess();

        const urlParams = new URLSearchParams(window.location.search);
        UTM_Source = urlParams.get("utm_source");
        UTM_Medium = urlParams.get("utm_medium");
        UTM_Campaign = urlParams.get("utm_campaign");

        let newLocal = address;
        if (inputPhone.toString().length >= 10 && petName.trim() !== "" && city !== "" && couponCode.trim() !== "") {
            // handleLocationAccess();
            setTrainingLoading(true);
            const data = {
                data: {
                    ContactNo: inputPhone,
                    DateOfRegistration: dateOfReg,
                    TimeOfRegistration: timeOfReg,
                    Address: "",
                    location: address,
                    FormType: "Direct Form",
                    UTM_Source: UTM_Source || "",
                    UTM_Medium: UTM_Medium || "",
                    UTM_Campaign: UTM_Campaign || "",
                    TrialDate: "",
                    PetName: petName,
                    City: city,
                    CouponCode: couponCode,
                },
            };

            await axios
                .post(
                    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData",
                    data
                )
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });
            setTrainingLoading(false);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: `Training Registration Fired From Mobile Service Page Form`,
            });
            navigate("/thankPage");
        } else {
            if (inputPhone.toString().length < 10) {
                setTrainingPhoneValidation(false);
                trainingPhoneRef.current.value = "";
            } else if (petName.trim() === "") {
                setNamePetValidation(false);

            } else {
                setCityValidation(false);
            }

            return;
        }
    }

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handlePetNameChange = (event) => {
        setPetName(event.target.value);
    };


    let [couponCode, setCouponCode] = useState("");
    let [couponCodeValidation, setCouponCodeValidation] = useState();

    const handleCouponCodeChange = (event) => {
        setCouponCode(event.target.value);
        if (couponCode.trim() !== "") setCouponCodeValidation(true);
        else setCouponCodeValidation(false);
    };

    return (
        <div className="training-page-mobile-view-form-container">
            <div
                className="training-page-mobile-view-form-header"
            >
                <div>Book a FREE Training Session</div>
            </div>
            <div ref={sectionRef}>
                <div className="training-page-mobile-view-form-phone-container">
                    <div className="training-page-mobile-view-phone-check-container">
                        <div>Phone Number <img src={phoneIcon} style={{ width: "15px", height: "15px", marginLeft: "2px" }} /></div>
                        {trainingPhoneValidation ? null : (
                            <div className="home-page-walking-form-mobile-feedback">
                                Please enter a valid number
                            </div>
                        )}
                    </div>
                    <input
                        ref={trainingPhoneRef}
                        onChange={() => setTrainingPhoneValidation(true)}
                        type="number"
                        className="training-page-mobile-view-input"
                        placeholder="Enter phone number"
                        style={{ fontSize: "17px" }}
                    />

                    <div
                        className="training-page-mobile-view-phone-check-container"
                        style={{ marginTop: "0.5rem" }}
                    >
                        <div>Enter Pet Name  <img src={dogsIcon} style={{ width: "15px", height: "15px", marginLeft: "2px" }} /></div>
                        {petNameValidation ? null : (
                            <div className="home-page-desktop-phone-validation-text">
                                Please enter petname
                            </div>
                        )}
                    </div>
                    <input
                        type="text"
                        className="training-page-mobile-view-input"
                        placeholder="Enter Pet Name"
                        style={{ fontSize: "17px" }}
                        value={petName}
                        onChange={(event) => {
                            handlePetNameChange(event);
                            setNamePetValidation(true);
                        }}
                        required
                    />

                    <div
                        className="training-page-mobile-view-phone-check-container"
                        style={{ marginTop: "0.5rem" }}
                    >
                        <div>Select City <img src={locationIcon} style={{ width: "15px", height: "15px", marginLeft: "2px" }} /> </div>
                        {(cityValidation === undefined || cityValidation !== false) ? null : (
                            <div className="home-page-desktop-phone-validation-text">
                                Select City
                            </div>
                        )}
                    </div>
                    <select
                        className="training-page-mobile-view-input"
                        style={{ fontSize: "17px" }}
                        value={city}
                        onChange={(event) => {
                            handleCityChange(event);
                            setCityValidation(true);
                        }}
                        required
                    >
                        <option value="" disabled selected>
                            Select City
                        </option>
                        <option value="Delhi NCR">Delhi NCR</option>
                        <option value="Mumbai Metropolitan City">Mumbai Metropolitan City</option>
                    </select>

                    <div
                        className="training-page-mobile-view-phone-check-container"
                        style={{ marginTop: "1rem" }}
                    >
                        <div className="flex items-center">
                            Enter Coupon Code
                            {(cityValidation === undefined || cityValidation !== false) ? null : (
                                <div className="home-page-desktop-phone-validation-text">
                                    Please enter coupon code
                                </div>
                            )}
                        </div>
                    </div>
                    <input
                        type="text"
                        className="training-page-mobile-view-input"
                        placeholder="Enter Pet Name"
                        style={{ fontSize: "17px" }}
                        value={couponCode}
                        onChange={(event) => {
                            handleCouponCodeChange(event);
                            setCouponCodeValidation(true);
                        }}
                    />
                </div>
                <div className="training-page-mobile-view-form-button">
                    {trainingLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div
                            className="bookingbutton"
                            style={{
                                width: "100%",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                marginTop: "10px",
                            }}
                        >
                            <button
                                onClick={() => trainingSubmissionHandler()}
                                className="booksession-mobile-form"
                                style={{ width: "100%" }}
                            >
                                Book a FREE Session
                            </button>
                        </div>
                    )}
                </div>
                <div style={{ paddingTop: "2rem" }}>
                    <img src={trainingFooterMobile} style={{ width: "21rem" }} />
                </div>
                <div
                    className="training-page-mobile-view-form-footer"
                    style={{ margin: "10px 10px 10px 14px" }}
                >
                    <i>We are mission driven & a Family of Pet Lovers</i>
                </div>
            </div>
        </div>
    );
};
export default TrainingPageForm;
