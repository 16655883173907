import { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import trainingFooterDesktop from "../../../images/trainingFooterDesktop.png";
import phoneIcon from "../../../images/telephoneIcon.png";
import dogsIcon from "../../../images/dogsIcon.png";
import locationIcon from "../../../images/locationIcon.png";

const HomePageForm = (props) => {
    const navigate = useNavigate();

    const [phoneValidation, setPhoneValidation] = useState(true);
    const [petNameValidation, setNamePetValidation] = useState(true);
    const [cityValidation, setCityValidation] = useState();
    const [couponCodeValidation, setCouponCodeValidation] = useState();
    const [loading, setLoading] = useState(false);
    const date = new Date();
    const [address1, setAddress] = useState("");
    const [city, setCity] = useState("");

    // declaration of refs
    const phoneRef = useRef(0);
    const dateRef = useRef("");
    const addressRef = useRef("");

    const [petName, setPetName] = useState("");
    const [couponCode, setCouponCode] = useState("");

    async function submitWalkingTrainingDataHandler(path) {
        let link = "";
        const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
        const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
            }-${date.getFullYear()}`;

        const stringPhone = phoneRef.current.value.toString();

        let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

        const urlParams = new URLSearchParams(window.location.search);
        UTM_Source = urlParams.get("utm_source");
        UTM_Medium = urlParams.get("utm_medium");
        UTM_Campaign = urlParams.get("utm_campaign");
        console.log(dateRef.current.value);
        let data = {
            data: {
                ContactNo: phoneRef.current.value,
                DateOfRegistration: dateRef.current.value || dateOfReg,
                location: address1,
                TimeOfRegistration: timeOfReg,
                Address: "",
                FormType: "Direct Form",
                UTM_Source: UTM_Source || "",
                UTM_Medium: UTM_Medium || "",
                UTM_Campaign: UTM_Campaign || "",
                TrialDate: "",
                PetName: petName,
                City: city,
                CouponCode: couponCode,
            },
        };



        if (
            addressRef !== "" &&
            stringPhone.length >= 10 &&
            petName.trim() !== "" &&
            city !== "" &&
            couponCode.trim() !== ""
        ) {
            setLoading(true);
            if (path === "walking") {
                link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
            } else if (path === "training") {
                console.log("Submitting the training data!");
                link =
                    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData";
            }

            await axios
                .post(link, data)
                .then((response) => {
                    console.log("hey2", response);
                })
                .catch((err) => {
                    console.log("hey3", err);
                });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: `${path} Registration Fired From Desktop Service Page Form`,
            });

            phoneRef.current.value = "";
            setLoading(false);
            navigate("/thankPage");
        } else if (stringPhone.length < 10) {
            setPhoneValidation(false);
            phoneRef.current.value = "";
        } else if (petName.trim() === "") {
            setNamePetValidation(false);
        } else {
            setCityValidation(false);
        }
    }

    const handleCityChange = (event) => {
        setCity(event.target.value);

        if (city !== "") setCityValidation(true);
        else setCityValidation(false);
    };

    const handlePetNameChange = (event) => {
        setPetName(event.target.value);

        if (petName.trim() !== "") setNamePetValidation(true);
        else setNamePetValidation(false);
    };

    const handleCouponCodeChange = (event) => {
        setCouponCode(event.target.value);
        if (couponCode.trim() !== "") setCouponCodeValidation(true);
        else setCouponCodeValidation(false);
    };

    return (
        <div className="home-page-desktop-intro-form-container2">
            <div className="walking-page-desktop-intro-form-header">
                <div>Book a FREE Training Session</div>
            </div>
            <div className="walking-page-desktop-intro-form-contents-container">
                <div className="walking-page-desktop-intro-form-contents-upper">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "auto"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "row" }} className="gap-4">
                            <div
                                className="walking-page-desktop-intro-form-contents-upper-left"
                            >
                                <div className="home-page-desktop-input-container">
                                    <div className="flex gap-4 items-center">
                                        Phone Number
                                        <img
                                            src={phoneIcon}
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                marginLeft: "2px",
                                            }}
                                        />{" "}
                                    </div>

                                    {phoneValidation ? null : (
                                        <div className="home-page-desktop-phone-validation-text">
                                            Please enter a valid phone number
                                        </div>
                                    )}
                                </div>
                                <input
                                    onChange={() => setPhoneValidation(true)}
                                    ref={phoneRef}
                                    className="walking-page-phone-input !w-full !max-w-[350px]"
                                    type="number"
                                    placeholder="Enter phone number"
                                    name="phone"
                                    required
                                />
                            </div>

                            <div className="walking-page-desktop-intro-form-contents-upper-left">
                                <div className="home-page-desktop-input-container">
                                    <div className="flex gap-4 items-center">
                                        Enter Pet Name{" "}
                                        <img
                                            src={dogsIcon}
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                marginLeft: "2px",
                                            }}
                                        />{" "}
                                    </div>
                                    {petNameValidation ? null : (
                                        <div className="home-page-desktop-phone-validation-text">
                                            Please enter petname
                                        </div>
                                    )}
                                </div>
                                <input
                                    //   onChange={() => setPhoneValidation(true)}
                                    //   ref={phoneRef}
                                    className="walking-page-phone-input !w-full !max-w-[350px]"
                                    type="text"
                                    placeholder="Enter Pet Name"
                                    name="name"
                                    value={petName}
                                    onChange={(event) => {
                                        handlePetNameChange(event);
                                        setNamePetValidation(true);
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }} className="mt-[1rem] gap-4">
                            <div className="walking-page-desktop-intro-form-contents-upper-left">
                                <div className="home-page-desktop-input-container">
                                    <div className="flex gap-4 items-center">
                                        Select City{" "}
                                        <img
                                            src={locationIcon}
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                marginLeft: "2px",
                                            }}
                                        />{" "}
                                        {cityValidation === undefined ||
                                            cityValidation !== false ? null : (
                                            <div className="home-page-desktop-phone-validation-text">
                                                Select City
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <select
                                    // onChange={() => setPhoneValidation(true)}
                                    // ref={phoneRef}
                                    className="walking-page-phone-input !w-full !max-w-[350px]"
                                    name="city"
                                    value={city}
                                    // onChange={handleCityChange}
                                    onChange={(event) => {
                                        handleCityChange(event);
                                        setCityValidation(true);
                                        setCity(event.target.value);
                                    }}
                                    required
                                >
                                    <option value="" disabled selected>
                                        Select City
                                    </option>
                                    <option value="Delhi NCR">Delhi NCR</option>
                                    <option value="Mumbai Metropolitan City">
                                        Mumbai Metropolitan City
                                    </option>
                                </select>
                            </div>
                            <div className="walking-page-desktop-intro-form-contents-upper-left">
                                <div className="home-page-desktop-input-container">
                                    <div className="flex gap-4 items-center">
                                        Enter Coupon Code{" "}
                                        {cityValidation === undefined ||
                                            cityValidation !== false ? null : (
                                            <div className="home-page-desktop-phone-validation-text">
                                                Please Enter Coupon Code
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <input
                                    className="walking-page-phone-input !w-full !max-w-[350px]"
                                    type="text"
                                    placeholder="Enter Coupon Code"
                                    name="couponCode"
                                    value={couponCode}
                                    onChange={(event) => {
                                        handleCouponCodeChange(event);
                                        setCityValidation(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="m-auto">
                            <div
                                className="walking-page-desktop-intro-form-contents-upper-right"
                                style={{ marginTop: "2rem" }}
                            >

                                <div className="home-page-intro-bottom-btn">
                                    {loading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <button
                                            onClick={() =>
                                                submitWalkingTrainingDataHandler("training")
                                            }
                                            className="booksession-form"
                                        >
                                            Book a FREE Session
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: "2rem" }}>
                    <img
                        src={trainingFooterDesktop}
                        style={{ width: "40rem", height: "10rem" }}
                    />
                </div>
                <div className="walking-page-desktop-intro-form-contents-lower">
                    <i>We are mission driven & a Family of Pet Lovers</i>
                </div>
            </div>
        </div>
    );
};

export default HomePageForm;
