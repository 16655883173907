import "./MobileFormsCSS.css";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import trainingFooterMobile from "../../../images/trainingFooterMobile.png"

const TrainingPageForm = (props) => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const trainingPhoneRef = useRef(0);
  const petRef = useRef("");

  const location = useLocation().pathname;

  const [trainingPhoneValidation, setTrainingPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [trainingLoading, setTrainingLoading] = useState(false);
  const [address, setAddress] = useState("");

  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }

  async function trainingSubmissionHandler() {
    const inputPhone = trainingPhoneRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    let UTM_Source,
      UTM_Medium,
      UTM_Campaign = "";

    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get("utm_source");
    UTM_Medium = urlParams.get("utm_medium");
    UTM_Campaign = urlParams.get("utm_campaign");

    if (inputPhone.toString().length >= 10) {
      setTrainingLoading(true);
      const data = {
        data: {
          ContactNo: inputPhone,
          DateOfRegistration: dateOfReg,
          TimeOfRegistration: timeOfReg,
          location: address,
          Address: "",
          AnimalType: "Cat",
          FormType: "Direct Form",
          UTM_Source: UTM_Source || "",
          UTM_Medium: UTM_Medium || "",
          UTM_Campaign: UTM_Campaign || "",
          TrialDate: "",
        },
      };

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData",
          data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setTrainingLoading(false);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Training Registration Fired From Mobile Service Page Form`,
      });
      navigate("/thankPage");
    } else {
      if (inputPhone.toString().length < 10) {
        setTrainingPhoneValidation(false);
        trainingPhoneRef.current.value = "";
      }

      return;
    }
  }

  return (
    <div className="training-page-mobile-view-form-container">
      <div
        className="training-page-mobile-view-form-header"
        onClick={scrollToSection}
      >
        <div>Book a FREE CAT Training Session</div>
      </div>
      <div ref={sectionRef}>
        <div className="training-page-mobile-view-form-phone-container">
          <div className="training-page-mobile-view-phone-check-container">
            <div>Phone Number</div>
            {trainingPhoneValidation ? null : (
              <div className="home-page-walking-form-mobile-feedback">
                Please enter a valid number
              </div>
            )}
          </div>
          <input ref={trainingPhoneRef} onChange={() => setTrainingPhoneValidation(true)} type="number" className='training-page-mobile-view-input' placeholder='Enter phone number' style={{ fontSize: "17px" }} />
        </div>
        {
          location == '/dog-training-meta' && <div className='training-page-mobile-view-form-phone-container'>
            <div className='training-page-mobile-view-phone-check-container'>
              <div>
                Pet Name
              </div>
              {
                petValidation ? null : (
                  <div className='home-page-walking-form-mobile-feedback'>
                    Please enter pet name
                  </div>
                )
              }
            </div>
            <input ref={petRef} onChange={() => setPetValidation(true)} type="text" className='training-page-mobile-view-input' placeholder='Enter pet name' style={{ fontSize: "17px" }} />
          </div>
        }
        <div className='training-page-mobile-view-form-button'>
          {
            trainingLoading ? (<LoadingSpinner />) : (
              <div className="bookingbutton" style={{ width: "100%", paddingLeft: "30px", paddingRight: "30px", marginTop: "10px" }}>
                <button onClick={() => trainingSubmissionHandler()} className="booksession-mobile-form" style={{ width: "100%" }}>Book a FREE Session</button>
              </div>
            )
          }

        </div>
        <div style={{ paddingTop: "2rem" }}>
          <img src={trainingFooterMobile} style={{ width: "21rem" }} />
        </div>
        <div
          className="training-page-mobile-view-form-footer"
          style={{ margin: "10px 10px 10px 14px" }}
        >
          <i>We are mission driven & a Family of Pet Lovers</i>
        </div>
      </div>
    </div>
  );
};

export default TrainingPageForm;
