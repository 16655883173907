import { useEffect, useState } from 'react';
import NavigationBar from '../../Navigation/navigationBar/NavigationBar';
import TrainingPageForm from '../mobileForms/TrainingPage3Form';

const IntroContainerMobileTraining = (props) => {

  return (
    <div className='training-page-intro-mobile-view'>
      <NavigationBar btn={"training"} submissionType={"training"} homePage={true} page={"white"} className="home-page-mobile-navigation-container" />
      {/* <IntroContainer/> */}
      <div className='home-page-intro-mobile-view-container'>
        <h1 className='intro-heading-mobile-view-header'>
          <div className='home-page-intro-mobile-view-heading-training'>
            <div>Get your Pet a <span className='pink-bold'>Loving</span> &</div>
            <div><span className='pink-bold'>Professional</span> Trainer!</div>
          </div>
        </h1>
        <div className='home-page-intro-mobile-view-points-training'>
          <div className='home-page-intro-mobile-view-point'>
            <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
            <div>INDIA’s most Scientific and Advanced Research backed Curriculum</div>
          </div>
          <div className='home-page-intro-mobile-view-point'>
            <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
            <div>EVERY Session is Agenda Driven & Purpose-Led</div>
          </div>
        </div>
        <div className="home-page-mobile-form-container">
          <TrainingPageForm />
        </div>
      </div>
    </div>

  );
};

export default IntroContainerMobileTraining;